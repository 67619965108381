import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogsService } from '../blogs.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-blogs',
  templateUrl: './view-blogs.component.html',
  styleUrl: './view-blogs.component.scss'
})
export class ViewBlogsComponent implements OnInit{

  constructor(
    private router:Router,
    private _route : ActivatedRoute,
    private blogService : BlogsService,
    private title:Title,
    private metaTagService: Meta
  ){}


  blogDetail : any;
  ngOnInit(): void {
    this._route.params.subscribe(
      (param:any)=>{
        this.blogService.getSingleBlogDetail(param.id).subscribe(
          (res:any) => {
            console.log(res.data[0]);
            this.blogDetail = res.data[0];
            this.title.setTitle(this.blogDetail.blogMeta.metaTitle);
            this.metaTagService.updateTag({name:'description',content:this.blogDetail.blogMeta.metaDescription.content});
          },
          (err) => {
            console.log(err);
          }
        )
      }
    )
  }
  navigateBack(){
    this.router.navigate(['/blogs'])
  }
}
