import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
  ) { }

  getAllBlogs(data:any){
    return this.http.post(
      environment.baseUrl+`business/blog/business`,data
    )
  }

  getSingleBlogDetail(blogId:any){
    return this.http.get(
      environment.baseUrl+`business/blog/displayId/${blogId}`
    )
  }
}
